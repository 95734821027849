import React, { Component } from "react";

class TitleHeader extends Component{
  render(){


// const TitleHeader = (props) => {
    // console.log(props);
  return (
    <div className="contener-title" >
      <div className="bg-img">
        <div className="bg-color">
          <div>
            <h1 className="header-title">{this.props.title}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
}


export default TitleHeader;
