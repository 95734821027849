import React, { Component } from "react"
import logo from "../images/logo.svg"
import Map from "../components/maps"

import goTo from "../images/goto.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    console.log("ppp")
    console.log(props)
  }
  state = {
    isMobile: typeof window !== 'undefined' && window.innerWidth
  }
  static defaultProps = {
    center: {
      lat: 50.0345205,
      lng: 19.93278380000004,
    },
    zoom: 14,
  }

  componentDidMount() {
    console.log("componentWillMount");
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ isMobile: window.innerWidth });
  };

  render() {
    let goToNavi;
    if (this.state.isMobile > 1200) {
      goToNavi = (
        <a href="https://goo.gl/maps/rMDPkcpwUG3zLzQD8" target="_blank" rel="noopener noreferrer">
              <img src={goTo} alt="goto" />
              <p>Otwórz Google Maps</p>
            </a>
      )
    } else {
      goToNavi = (
        <a href="google.navigation:q=50.0344659,19.9324407">
          <img src={goTo} alt="goto" />
          <p>Prowadź do</p>
        </a>)
        ;
    }
    return (
      <div id="contact" ref={this.props.refProp}>
        <footer id="contact">
          <div className="footer__logo">
            <img src={logo} alt="Logo dental art studio" />
          </div>
          <div className="footer__address">
            <h3>Adres:</h3>
            <p>
              Osiedle „Fi” <br />
              ul. Tadeusza Szafrana 5a/u7 <br />
              30-363 Kraków <br />
            </p>
          </div>
          <div className="footer__open">
            <h3>Godziny otwarcia:</h3>
            <p>
              Poniedziałek 8:00 – 17:00 <br />
              Wtorek 09:00 – 19:00 <br />
              Środa 8:00 – 21:00 <br />
              Czwartek 9:00 – 21:00 <br />
              Piątek 8:00 – 16:00 <br />
              Sobota Zamknięte <br />
              Niedziela Zamknięte <br />
            </p>
          </div>
          <div style={{ gridColumn: "1/4" }}>
            <Map />
          </div>
          {/* <div>
            <p>
              <a href="geo:50.0344659,19.9324407">
                Zwykły punkt z wyborem nawigacji</a>
            </p>
            <p>
              Start z wyborem nawigacji
            </p>

            <a href="https://goo.gl/maps/RMbxWNkM22nwgq1v6">Ustawia dokąd zawsze w google maps</a>
          </div> */}
          <div className="go-to">
            {goToNavi}
          </div>
          <div className="footer__design">Design Paweł Idzikowski</div>
        </footer>
      </div>
    )
  }
}

export default Footer
