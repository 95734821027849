import React from "react";
import Dorota from "../../images/dorota.png";
import TitleHeader from "../../components/TitleHeader";
import GalleryComponent from "../../components/GalleryComponent";
import cert1 from "../../images/certyfikaty_dorota/cer_dor_1.jpg";
import cert2 from "../../images/certyfikaty_dorota/cer_dor_2.jpg";
import cert3 from "../../images/certyfikaty_dorota/cer_dor_3.jpg";
import cert4 from "../../images/certyfikaty_dorota/cer_dor_4.jpg";
import cert5 from "../../images/certyfikaty_dorota/cer_dor_5.jpg";
import cert6 from "../../images/certyfikaty_dorota/cer_dor_6.jpg";
import cert7 from "../../images/certyfikaty_dorota/cer_dor_7.jpg";
import cert8 from "../../images/certyfikaty_dorota/cer_dor_8.jpg";
import cert9 from "../../images/certyfikaty_dorota/cer_dor_9.jpg";
import Layout from "../../components/layout";

const certification = [
  cert1,
  cert2,
  cert3,
  cert4,
  cert5,
  cert6,
  cert7,
  cert8,
  cert9
];

const handleClick = () => {
  console.log("click");
  window.medicall_modal('4d2681f0fb3e6bef7d87');
  return false;
};

const DorotaMaliszewska = () => {
  return (
    <Layout>
      <TitleHeader title="Dorota Maliszewska" />
      <div className="content zespol-template">
        <div className="img-contener">
          <img src={Dorota} className="img" alt="Dorota" />
        </div>

        <div className="text-contener">
          <h1>Dorota Maliszewska</h1>
          <p className="under-h1">Higienistka Stomatologiczna</p>
          <img
            src="https://sztukastomatologii.pl/wp-content/uploads/2018/07/zapraszam-300x89.png"
            alt="podpis"
            className="img podpis"
          />
          <p>
            Dorota Maliszewska Absolwentka Medycznego Studium Zawodowego.
            Związana ze stomatologia od 10 lat. Stale poszerza swoje zawodowe
            kompetencje uczestnicząc w szkoleniach oraz kursach z zakresu
            stomatologii i higienizacji. Motywacją do dalszego rozwoju
            zawodowego jest obserwowanie znakomitych efektów leczenia oraz
            zadowoleni pacjenci. Miła, energiczna i bardzo otwarta osoba.Lubi
            aktywnie spędzać czas wolny.
          </p>
          <button class="btn btn-register" type="button" onClick={handleClick}>
            Zarejestruj się
          </button>
        </div>
      </div>
      <GalleryComponent className="content" img={certification} />
    </Layout>
  );
};
export default DorotaMaliszewska;
