import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../styles/nav.scss"
import classNames from "classnames"

// import Image from "../components/image"
import Logo from "../images/logo.svg"

const onMouseLeave = event => {
  console.log(event)
  event.preventDefault()
  event.currentTarget.querySelector(".drop-down").style.display = "none"
}
const onMouseEnter = event => {
  console.log(event)
  event.preventDefault()
  event.currentTarget.querySelector(".drop-down").style.display = "block"
  //   this.setState({ show: "test" })
}

function nav() {
  const [show, setShow] = useState(false)
  const showMenu = event => {
    console.log("click")
    setShow(!show)
  }
  const classMenu = classNames({
    show: show,
    none: !show,
    nav: true,
  })
  return (
    <div>
      <div className="menu-mobile only-mobile">
        <FontAwesomeIcon
          className="only-mobile"
          // for="menu"
          onClick={showMenu}
          icon="bars"
          size="2x"
        />
        <img className="" src={Logo} alt="logo" />
      </div>
      <nav
        className={classMenu}
        onClick={showMenu}
        // onClick={this.showMenu}
        // className={this.state.navFixed ? "nav content fix" : "nav content"}
      >
        {/* <div className="mobile-view"> */}
        {/* <Link className="logo-menu"> */}
        {/* <Image /> */}
        <img className="logo-menu" src={Logo} alt="logo" />
        {/* <img src={logo} alt="Logo dental art studio" /> */}
        {/* </Link> */}

        {/* </div> */}
        <input type="checkbox" className="input-show-menu" name="menu" />
        <ul>
          {/* <ul className={this.state.dropMobileMenu ? "show menu" : "menu"} onClick={this.showMenu}> */}
          <li>
            <Link to="/" activeClassName="selected">
              O nas
            </Link>
          </li>
          {/* <li> */}
          <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Link
              to="/uslugi"
              activeClassName="selected"
              className="trigger-drop-down"
            >
              Usługi
            </Link>
            {/* style={{ display: this.state.show }} */}
            <ul className="drop-down">
              {/* <ul className="drop-down" onClick={this.removeDropDown}> */}
              <li>
                <Link to="/uslugi/stomatologia-estetyczna">
                  Stomatologia estetyczna
                </Link>
              </li>
              <li>
                <Link to="/uslugi/implantologia">Implantologia</Link>
              </li>
              <li>
                <Link to="/uslugi/kompleksowa-opieka">
                  Kompleksowa opieka
                  <br />
                  stomatologiczna
                </Link>
              </li>
              <li>
                <Link to="/uslugi/medycyna-estetyczna">
                  Medycyna estetyczna
                </Link>
              </li>
            </ul>
          </li>
          <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Link
              to="/zespol"
              activeClassName="selected"
              className="trigger-drop-down"
            >
              Zespół
            </Link>

            <ul className="drop-down">
              {/* onClick={this.removeDropDown}> */}
              <li>
                <Link to="/zespol/radoslaw-lysek">
                  dr n. med. Radosław Łysek
                </Link>
              </li>
              <li>
                <Link to="/zespol/dorota-maliszewska/">Dorota Maliszewska</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/cennik" activeClassName="selected">
              Cennik
            </Link>
          </li>
          <li>
            <Link to="/blog" activeClassName="selected">
              Blog
            </Link>
          </li>
          <li>
            <Link to="/#contact" duration={500} >
              {/* <Link href="contact" smooth={true} duration={500}> */}
              Kontakt
            </Link>
          </li>
        </ul>
        {/* </div> */}
      </nav>
    </div>
  )
}

export default nav
